import { useEffect, useMemo, useState } from 'react';
import { Dealer } from '../../types/Dealer';
import { User } from '../../types/User';

interface UserListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseUserListFilterState {
    filteredUsers: User[];
    userListCriteria: UserListCriteria;
    setUserListCriteria: (userListCriteria: UserListCriteria) => void;
    resetUserListCriteria: () => void;
    users: User[];
    setUsers: (users: User[]) => void;
}

const initialUserListCriteriaState: UserListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

const useUserListFilter = (
    initialUsers: User[] = [],
    initialUserListCriteria: UserListCriteria = initialUserListCriteriaState
): UseUserListFilterState => {
    const [users, setUsers] = useState<User[]>(initialUsers);
    const [userListCriteria, setUserListCriteria] = useState<UserListCriteria>(initialUserListCriteria);

    const filteredUsers = useMemo((): User[] => {
        return users.filter((user: User) => {
            const matchesSearch =
                user.userName.toLowerCase().indexOf(userListCriteria.searchText.toLowerCase()) > -1 ||
                user.emailAddress.toLowerCase().indexOf(userListCriteria.searchText.toLowerCase()) > -1 ||
                user.name.toLowerCase().indexOf(userListCriteria.searchText.toLowerCase()) > -1;

            const matchesAgencyId =
                userListCriteria.agencyId !== null ? user.agencyId === userListCriteria.agencyId : true;

            const matchesAdvertiserId =
                userListCriteria.advertiserId !== null
                    ? user.dealers.some((advertiser: Dealer) => advertiser.id === userListCriteria.advertiserId)
                    : true;

            return matchesSearch && matchesAgencyId && matchesAdvertiserId;
        });
    }, [users, userListCriteria]);

    useEffect(() => {
        setUsers(initialUsers);
    }, [initialUsers]);

    const resetUserListCriteria = (): void => {
        setUserListCriteria(initialUserListCriteriaState);
    };

    return {
        filteredUsers,
        userListCriteria,
        setUserListCriteria,
        resetUserListCriteria,
        users,
        setUsers,
    };
};

export type { UseUserListFilterState, UserListCriteria };
export { useUserListFilter };
