import { AmcAttribution as Resource } from '../../types/AmcAttribution';
import { useResourceListFilter } from './ResourceListFilter';

interface ResourceListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseResourceListFilterState {
    filteredAmcAttributions: Resource[];
    amcAttributionListCriteria: ResourceListCriteria;
    setAmcAttributionListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetAmcAttributionListCriteria: () => void;
    amcAttributions: Resource[];
    setAmcAttributions: (resources: Resource[]) => void;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

// const filterResources = (resources: Resource[], resourceListCriteria: ResourceListCriteria): Resource[] => {
//     return resources;
// };

const useAmcAttributionListFilter = (
    initialResources: Resource[] = [],
    initialResourceListCriteria: ResourceListCriteria = initialResourceListCriteriaState
): UseResourceListFilterState => {
    const { resourceListCriteria, setResourceListCriteria, filteredResources, resources, setResources } =
        useResourceListFilter<Resource>({
            initialResources,
            initialResourceListCriteria: initialResourceListCriteriaState,
            filterResources: null,
        });

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    return {
        filteredAmcAttributions: filteredResources,
        amcAttributionListCriteria: resourceListCriteria,
        setAmcAttributionListCriteria: setResourceListCriteria,
        resetAmcAttributionListCriteria: resetResourceListCriteria,
        amcAttributions: resources,
        setAmcAttributions: setResources,
    };
};

export type {
    UseResourceListFilterState as UseAmcAttributionListFilterState,
    ResourceListCriteria as AmcAttributionListCriteria,
};
export { useAmcAttributionListFilter };
