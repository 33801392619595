import { useState, useEffect, ChangeEvent } from 'react';
import React from 'react';
import { Typography, Switch, Tooltip, Button, Box, Modal, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CDGrid from '../../components/CDGridPro';
import AccessGuard from '../../components/AccessGuard';
import TabPanel from '../../components/TabPanel';
import { USER_GROUP_ADMIN } from '../../types/User';
import ApiService from '../../ApiService';
import { DspAdGroupDict } from '../../types/DspEntity';
import theme from '../../theme';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

interface ITab {
    id: number;
    description: string;
}

const DspAdGroupDictsTab = ({ tab, activeTab }: { tab: ITab; activeTab: number }) => {
    const defaultPageSize: number = 20;
    const pageValueArray = [10, 20, 50, 75, 100];
    const [list, setList] = useState<DspAdGroupDict[] | null>(null);
    const [open, setOpen] = useState(false);
    const [editIds, setEditIds] = useState<number[]>([]);
    const [adGroupEdit, setAdGroupEdit] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [rowSetPerPage, setRowSetPerPage] = React.useState(defaultPageSize);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = parseInt(event?.target?.value);
        if (pageValueArray.includes(value)) {
            setRowSetPerPage(value);
        } else {
            // Handle invalid value or reset to default
            setRowSetPerPage(defaultPageSize);
        }
    };

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(event.target.checked);
    };

    const resetForm = () => {
        setAdGroupEdit('');
        setIsDisabled(false);
    };

    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    const handleOpen = () => {
        resetForm();
        setOpen(true);
    };

    const editModalStyles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        borderRadius: '4px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        ApiService.getAllAdGroupDicts()
            .then((response) => {
                setList(response?.data);
            })
            .catch(() => {
                setList([]);
            });
    }, []);

    // Handle switch toggle
    const handleSwitchChange = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
        if (!list) return;
        const checked = event.target.checked;

        setList(
            list.map((row) => {
                if (row.id === id) {
                    const newRow = { ...row, disabled: checked };
                    ApiService.updateAdGroupDict(newRow);
                    return newRow;
                }
                return row;
            })
        );
    };

    //function to send Data for Bulk Update
    function bulkChangeSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        ApiService.updateBulk({
            ids: editIds,
            dspAdGroupDict: {
                adGroupName: adGroupEdit,
                disabled: isDisabled,
            },
        }).then(() => {
            if (!list) return;
            setList(
                list.map((item) =>
                    editIds.includes(item.id) ? { ...item, adGroupName: adGroupEdit, disabled: isDisabled } : item
                )
            );
        });
        setEditIds([]);
        handleClose();
    }

    const columns: GridColDef[] = [
        {
            flex: 1,
            minWidth: 100,
            field: 'id',
            headerName: 'ID',
        },
        {
            flex: 2,
            minWidth: 100,
            field: 'type',
            headerName: 'Strategy Type',
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'adGroupName',
            headerName: 'Ad Group Value',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        Ad Group Value
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'inventoryName',
            headerName: 'Inventory Value',
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'inventoryMake',
            headerName: 'Make',
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'inventoryModel',
            headerName: 'Model',
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'inventoryTrim',
            headerName: 'Trim',
        },
        {
            flex: 2,
            minWidth: 100,
            field: 'createdDate',
            type: 'date',
            headerName: 'Created Date',
            valueGetter: (value) => Utils.getDate(value),
            valueFormatter: (value) => {
                return Utils.formatESTDateLong(value);
            },
        },
        {
            flex: 1,
            minWidth: 100,
            field: 'disabled',
            headerName: 'Disable',
            type: 'boolean',
            renderCell: (params) => {
                const value = params.value;
                return <Switch checked={value} onChange={handleSwitchChange(params.id as number)} />;
            },
        },
    ];

    return (
        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
            <TabPanel index={tab.id} value={activeTab} sx={{ p: 0 }}>
                <Column gap={2}>
                    <Row justifyContent="space-between" alignItems="center">
                        <Row gap={0} sx={{ width: '100%' }}>
                            <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                                Instruction:{' '}
                                <Typography
                                    component="span"
                                    color="text.secondary"
                                    variant="subtitle2"
                                    fontWeight={500}
                                >
                                    {tab.description}
                                </Typography>
                            </Typography>
                        </Row>
                        <Row flex={0} justifyContent="flex-end">
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Rows Per Page</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={rowSetPerPage.toString()}
                                    label="Rows Per Page"
                                    onChange={handleChange}
                                >
                                    {pageValueArray?.map((value) => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            {editIds?.length > 1 ? (
                                <Button
                                    variant="text"
                                    onClick={handleOpen}
                                    startIcon={<BorderColorIcon />}
                                    sx={{ marginTop: '-1%' }}
                                >
                                    Bulk Edit
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Row>
                    </Row>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={editModalStyles}>
                            <Box sx={{ width: '100%' }}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ textAlign: 'center' }}
                                >
                                    Bulk Edit
                                </Typography>
                                <form onSubmit={bulkChangeSubmit}>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <Box sx={{}}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography>Ad Group Value</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Ad Group Value"
                                                    variant="outlined"
                                                    value={adGroupEdit}
                                                    size="small"
                                                    onChange={(e) => setAdGroupEdit(e.target.value)}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography sx={{ marginTop: '10%' }}>Disable</Typography>
                                                <Box sx={{ marginTop: '32px', marginRight: '156px' }}>
                                                    <Switch
                                                        checked={isDisabled}
                                                        onChange={handleToggleChange}
                                                        inputProps={{ 'aria-label': 'Switch demo' }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10%' }}>
                                            <Button
                                                variant="contained"
                                                sx={{ width: '120px' }}
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                sx={{ width: '120px' }}
                                                onClick={handleClose}
                                                startIcon={<CloseIcon />}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Typography>
                                </form>
                            </Box>
                        </Box>
                    </Modal>
                    <CDGrid
                        id="dspAdGroupDicts"
                        variant="contained"
                        pageSize={rowSetPerPage}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowSelectionModel={editIds}
                        pageSizeOptions={pageValueArray}
                        selectionsChanged={(ids) => {
                            const selectedIDs = new Set(ids);
                            setEditIds(Array.from(selectedIDs.values()) as number[]);
                        }}
                        rows={list === null ? [] : list}
                        processRowUpdate={(newRow, oldRow) => {
                            if (newRow.adGroupName !== oldRow.adGroupName) {
                                ApiService.updateAdGroupDict(newRow);
                                setList((prevRows) =>
                                    prevRows === null
                                        ? []
                                        : prevRows.map((row) => {
                                              if (row.id === newRow.id) {
                                                  const updatedRow = { ...row, adGroupName: newRow.adGroupName };
                                                  return updatedRow;
                                              } else {
                                                  return row;
                                              }
                                          })
                                );
                            }
                            return newRow;
                        }}
                        onProcessRowUpdateError={() => {}}
                    />
                </Column>
            </TabPanel>
        </AccessGuard>
    );
};

export default DspAdGroupDictsTab;
