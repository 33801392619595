import Heading from '../../components/Heading';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IconButton, InputAdornment, SelectChangeEvent, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { AdvertiserContext } from '../../AdvertiserContext';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';
import CDGrid from '../../components/CDGrid';
import { UserContext } from '../../App';
import Row from '../../components/Row';
import { LinkButton } from '../../components/LinkButton';
import ActiveStatus from '../../components/ActiveStatus';
import AgencySelector from '../../components/AgencySelector';
import Block from '../../components/Block';
import Column from '../../components/Column';
import ActiveStatusRadioSelect from '../../components/RadioSelect/ActiveStatusRadioSelect';

export default function DealerListPage() {
    const { advertiserContext, setAdvertiserContext } = useContext(AdvertiserContext);

    const [dealers, setDealers] = useState<Dealer[] | null>(null);
    const [allDealers, setAllDealers] = useState<Dealer[]>([]);
    const [searchText, setSearchText] = useState<string>(
        // Use a callback function so the local storage call only runs once.
        () => Utils.localStorage('dealerSearch', '')
    );
    const navigate = useNavigate();
    const [page, setPage] = useState(
        // Use a callback function so the local storage call only runs once.
        () => Number(localStorage.getItem('dealerGridPage'))
    );
    const { userContext } = useContext(UserContext);
    const [activeStatusFilter, setActiveStatusFilter] = useState('all');

    useEffect(() => {
        const initialSearchText = Utils.localStorage('dealerSearch', '');
        ApiService.getDealers()
            .then(function (response) {
                setAllDealers(response.data);
                setDealers(response.data);
                filterRows(initialSearchText, activeStatusFilter, response.data, advertiserContext.agencyId);
            })
            .catch(() => {
                setDealers([]);
            });
    }, []);

    const handleStatusChange = (event: SelectChangeEvent<string>): void => {
        const activeStatus = event.target.value;
        setActiveStatusFilter(activeStatus);
        filterRows(searchText, activeStatus, allDealers, advertiserContext.agencyId);
    };

    function clearSearch() {
        setSearchText('');
        filterRows('', activeStatusFilter, allDealers, advertiserContext.agencyId);
        localStorage.setItem('dealerSearch', '');
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(event.target.value, activeStatusFilter, allDealers, advertiserContext.agencyId);
        localStorage.setItem('dealerSearch', event.target.value);
    }

    function filterRows(searchText: string, activeStatus: string, allDealers: Dealer[], agencyId?: number | null) {
        const filteredRows = allDealers.filter((row: Dealer) => {
            let filterMatch =
                row.dealerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                (row.agency && row.agency.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (row.publicId && row.publicId.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
            filterMatch =
                filterMatch &&
                (activeStatus === 'all'
                    ? true
                    : activeStatus === 'active'
                    ? row.activeStatus === true
                    : row.activeStatus === false);

            if (agencyId !== null) {
                filterMatch = filterMatch && row.agency?.id === agencyId;
            }

            return filterMatch;
        });

        setDealers(filteredRows);
    }

    function onPageChange(page: number) {
        setPage(page);
        localStorage.setItem('dealerGridPage', String(page));
    }

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Advertisers</Heading>

                        <Row justifyContent="flex-end">
                            <LinkButton href="new" variant="contained" color="primary">
                                Add Advertiser
                            </LinkButton>
                        </Row>
                    </Row>
                </Column>
            </Block>

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="space-between">
                        <Row>
                            <AgencySelector
                                variant="outlined"
                                label="Agency"
                                agencySelected={(agencyId) => {
                                    setAdvertiserContext(null, agencyId);
                                    filterRows(searchText, activeStatusFilter, allDealers, agencyId);
                                }}
                                agencyId={advertiserContext.agencyId}
                                sx={{ width: 224 }}
                            />

                            <ActiveStatusRadioSelect
                                aria-label="status"
                                name="status"
                                value={activeStatusFilter}
                                onChange={handleStatusChange}
                            />
                        </Row>

                        <TextField
                            inputRef={(input) => input && input.focus()}
                            id="searchText"
                            value={searchText}
                            size="small"
                            label="Search Advertisers"
                            onChange={search}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined color="action" fontSize="small" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => clearSearch()} edge="end">
                                            <ClearOutlined color="action" fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Row>

                    <CDGrid
                        id="advertiserList"
                        page={page}
                        columns={[
                            {
                                minWidth: 160,
                                flex: 2,
                                field: 'agency',
                                headerName: 'Agency',
                                hide: !userContext.isAdmin(),
                                valueGetter: (params) => {
                                    return params.value?.name;
                                },
                            },
                            {
                                width: 120,
                                field: 'logo',
                                flex: 1,
                                headerName: 'Logo',
                                headerAlign: 'center',
                                align: 'center',
                                renderCell: (params) => (
                                    <img alt="" style={{ width: '100px' }} src={params.row.logoUrl}></img>
                                ),
                            },
                            {
                                width: 200,
                                field: 'dealerName',
                                headerName: 'Advertiser Name',
                                flex: 2,
                            },
                            {
                                width: 170,
                                field: 'city',
                                headerName: 'Location',
                                valueGetter: (params) => {
                                    return params.value ? params.value + ', ' + params.row.state : '';
                                },
                            },
                            { width: 170, field: 'publicId', headerName: 'Public Id' },
                            {
                                width: 100,
                                field: 'activeStatus',
                                headerName: 'Status',
                                headerClassName: 'grid-header',
                                renderCell: (params) => {
                                    return <ActiveStatus activeStatus={params.value} />;
                                },
                            },
                        ]}
                        onRowClick={(params) => {
                            navigate('' + params.row.id);
                        }}
                        rows={dealers === null ? [] : dealers}
                        loading={dealers === null}
                        autoHeight={false}
                        autoPageSize={true}
                        onPageChange={onPageChange}
                    />
                </Column>
            </Block>
        </Column>
    );
}
