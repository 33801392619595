import { useContext } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import { UserContext } from '../../../../../App';
import { TabsContainer, TabsContainerProps } from '../../../../../components/Tabs';

export const TABS = {
    ALL: 0,
    DISPLAY: 1,
    VIDEO: 2, // For deprecation
    ONLINE_VIDEO: 4,
    STREAMING_TV: 5,
    AUDIO: 3,
};

type ReportingMediaTabsProps = {
    slotProps?: {
        tabsContainer?: TabsContainerProps;
    };
} & TabsProps;

export default function ReportingMediaTabs(props: ReportingMediaTabsProps) {
    const { slotProps = {}, ...tabsProps } = props;

    const { userContext } = useContext(UserContext);
    const { isActiveMediaType } = useReportingDashboardPageContext();

    return (
        <TabsContainer sx={{ mt: 2 }} {...slotProps?.tabsContainer}>
            <Tabs {...tabsProps}>
                <Tab value={TABS.ALL} label="All Media" />
                {isActiveMediaType('Display') && <Tab value={TABS.DISPLAY} label="Display" />}
                {userContext.isAdmin() && isActiveMediaType('Video') && <Tab value={TABS.VIDEO} label="Video" />}
                {isActiveMediaType('Online Video') && <Tab value={TABS.ONLINE_VIDEO} label="Online Video" />}
                {isActiveMediaType('Streaming TV') && <Tab value={TABS.STREAMING_TV} label="Streaming TV" />}
                {isActiveMediaType('Audio') && <Tab value={TABS.AUDIO} label="Audio" />}
            </Tabs>
        </TabsContainer>
    );
}
