import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemIconProps,
    ListItemText,
    ListItemTextProps,
    Stack,
} from '@mui/material';
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

import { SidebarMenuItem } from './index';
import { useSidebarMenuItem } from '../../hooks/SidebarMenuItem';
import SidebarMenuSubList from './SidebarMenuSubList';

interface SidebarMenuListItemProps {
    item: SidebarMenuItem;
    handleCollapse?: (item: SidebarMenuItem) => void;
    slots?: {
        // ListItemButton?: typeof ListItemButton;
        // ListItemIcon?: typeof ListItemIcon;
        // ListItemText?: typeof ListItemText;
        ListItemButton?: any;
        ListItemIcon?: any;
        ListItemText?: any;
    };
    slotProps?: {
        listItemButton?: ListItemButtonProps;
        listItemIcon?: ListItemIconProps;
        listItemText?: ListItemTextProps;
    };
}

const SidebarMenuListItem = (props: SidebarMenuListItemProps) => {
    const { item, handleCollapse, slots, slotProps } = props;
    const {
        ListItemButton: SlotListItemButton = ListItemButton,
        ListItemIcon: SlotListItemIcon = ListItemIcon,
        ListItemText: SlotListItemText = ListItemText,
    } = slots || {};
    let {
        listItemButton: listItemButtonProps = {},
        listItemIcon: listItemIconProps = {},
        listItemText: listItemTextProps = {},
    } = slotProps || {};

    const {
        isSidebarMenuItemActive,
        isSidebarMenuItemCollapsable,
        isSidebarMenuItemTargetable,
        shouldSidebarMenuItemCollapse,
    } = useSidebarMenuItem(item);

    const renderActionIcon = (): React.ReactElement | null => {
        if (item?.actionIcon) {
            return item.actionIcon;
        }

        if (isSidebarMenuItemCollapsable()) {
            return item?.open ? <ArrowDropUpIcon color="action" /> : <ArrowDropDownIcon color="action" />;
        }

        if (isSidebarMenuItemTargetable()) {
            return <OpenInNewIcon color="action" fontSize="small" />;
        }

        return null;
    };

    listItemButtonProps = {
        ...listItemButtonProps,
        onClick: () => {
            if (handleCollapse) {
                item.open = !item.open;
                handleCollapse(item);
            }

            if (item?.onClick) {
                item.onClick(item);
            }
        },
        ...(item?.url && { component: Link, to: item.url, target: item?.target || '_self' }),
    };

    useEffect(() => {
        if (shouldSidebarMenuItemCollapse()) {
            if (handleCollapse) {
                item.open = true;
                handleCollapse(item);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <SlotListItemButton {...listItemButtonProps} selected={isSidebarMenuItemActive()}>
                <SlotListItemIcon
                    {...listItemIconProps}
                    sx={{
                        '&': {
                            minWidth: 44,
                        },
                    }}
                >
                    {item.icon}
                </SlotListItemIcon>
                <SlotListItemText
                    {...listItemTextProps}
                    primary={item.name}
                    primaryTypographyProps={{ variant: 'body2' }}
                />
                <Stack alignItems="center" justifyContent="center">
                    {renderActionIcon()}
                </Stack>
            </SlotListItemButton>

            {item?.children?.length && (
                <Collapse in={isSidebarMenuItemCollapsable() ? item?.open : true} timeout="auto" unmountOnExit>
                    <SidebarMenuSubList items={item.children} />
                </Collapse>
            )}
        </Fragment>
    );
};

export type { SidebarMenuListItemProps };
export default SidebarMenuListItem;
