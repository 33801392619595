import { useContext } from 'react';
import { User } from '../../types/User';
import { UserContext } from '../../App';

interface UseState {
    isUserImpersonating: () => boolean;
    startUserImpersonation: (user: User) => void;
    stopUserImpersonation: () => void;
}

const useUserImpersonation = (): UseState => {
    const { userContext, setUserContext } = useContext(UserContext);

    const isUserImpersonating = (): boolean => {
        return userContext.adminContext ? true : false;
    };

    const startUserImpersonation = (user: User): void => {
        setUserContext(user.id, user.userName, user.name, user.group, user.agencyId, user.agency?.name, user.agency);
    };

    const stopUserImpersonation = (): void => {
        if (isUserImpersonating()) {
            setUserContext(null, null, undefined, undefined, null, undefined);
        }
    };

    return {
        isUserImpersonating,
        startUserImpersonation,
        stopUserImpersonation,
    };
};

export type { UseState as UseUserImpersonationState };
export { useUserImpersonation };
