import {
    AlertColor,
    Backdrop,
    Badge,
    BadgeProps,
    Box,
    Button,
    CircularProgress,
    Grid,
    Popover,
    Stack,
    Tab,
    Tabs,
    TextField,
} from '@mui/material';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Block from '../../../components/Block';
import Column from '../../../components/Column';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { AMCWorkflow, AMCWorkflowExecutionRequest } from '../../../types/AMCData';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-sqlserver';
import { CloseOutlined, DirectionsRunOutlined, InfoOutlined, SaveOutlined } from '@mui/icons-material';
import ApiService from '../../../ApiService';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Range } from 'react-date-range';
import { TabsContainer } from '../../../components/Tabs';
import TabPanel from '../../../components/TabPanel';
import AmcWorkflowExecutionsGrid from './AmcWorkflowExecutionsGrid';
import AmcWorkflowExecuteDialog from './AmcWorkflowExecuteDialog';
import Utils from '../../../components/Utils';
import { Dealer } from '../../../types/Dealer';
import { AmcInstance } from '../../../types/AmcInstance';
import { styled } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import DataGrid from '../../../components/CDGrid';

export interface WorkflowEditPageProps {
    workflowId: string;
    sqlQuery: string;
    isNew: boolean;
    amcInstance: AmcInstance;
}

export default function AmcWorkflowEditPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const amcInstance: AmcInstance = location.state.amcInstance;
    const [workflow, setWorkflow] = useState<AMCWorkflow>(location.state);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [isNew, setIsNew] = useState(location.state.isNew);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [busy, setBusy] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [runDialogOpen, setRunDialogOpen] = useState(false);

    useEffect(() => {
        if (amcInstance) {
            ApiService.getDealersForAMCInstance(amcInstance.id).then((response) => {
                setDealers(response.data);
            });
        }
    }, [amcInstance]);

    function handleEditSQL(value: string) {
        setWorkflow({ ...workflow, sqlQuery: value });
    }

    function handleEditWorkflowId(event: ChangeEvent<HTMLInputElement>) {
        setWorkflow({ ...workflow, workflowId: event.target.value });
    }

    function handleSave() {
        setBusy(true);

        ApiService.saveAmcWorkflow(amcInstance.id, workflow, isNew)
            .then(() => {
                setIsNew(false);
                setBusy(false);
                showInfoMessage('success', 'Workflow has been saved.');
            })
            .catch((error) => {
                setBusy(false);
                showInfoMessage('error', error.response.data);
            });
    }

    function handleOpenRun() {
        setRunDialogOpen(true);
    }

    function handleDelete() {
        setBusy(true);
        ApiService.deleteAmcWorkflow(amcInstance.id, workflow.workflowId)
            .then(() => {
                setBusy(false);
                showInfoMessage('success', 'Workflow has been deleted.');
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch((error) => {
                setBusy(false);
                showInfoMessage('error', error.response.data);
            });
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function selectTab(event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    function runWorkflow(dateRange: Range | null) {
        setRunDialogOpen(false);
        if (dateRange !== null && dateRange.endDate) {
            setBusy(true);
            let endDate = dateRange.endDate;
            endDate.setDate(endDate.getDate() + 1);
            let request: AMCWorkflowExecutionRequest = {
                workflow: workflow,
                timeWindowStart: Utils.toMidnightUTC(dateRange.startDate).toISOString(),
                timeWindowEnd: Utils.toMidnightUTC(endDate).toISOString(),
            };

            ApiService.runWorkflow(amcInstance.id, request)
                .then(() => {
                    setBusy(false);
                    showInfoMessage('success', 'Workflow was successfully submitted');
                })
                .catch((error) => {
                    setBusy(false);
                    showInfoMessage('error', error.response.data);
                });
        }
    }

    let busyMessage;
    if (busy) {
        busyMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const StyledBadge = styled(Badge)<BadgeProps>(() => ({
        '& .MuiBadge-badge': {
            right: -5,
        },
    }));

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <AppBreadcrumbs
                        items={[
                            { label: 'AMC Workflows', href: '/amcworkflows' },
                            { label: workflow.workflowId || '...' },
                        ]}
                    />
                </Column>
            </Block>

            <Block disableGutters>
                <Column gap={2}>
                    <Block py={0}>
                        {busyMessage}
                        <Grid container spacing={2} sx={{ paddingTop: '15px', paddingBottom: '25px' }}>
                            <Grid item md={5}>
                                <TextField
                                    label="Workflow ID"
                                    size="small"
                                    variant="filled"
                                    fullWidth={true}
                                    value={workflow.workflowId}
                                    onChange={handleEditWorkflowId}
                                />
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={5}>
                                <Button variant="text" onClick={handleClick} color="info" startIcon={<InfoOutlined />}>
                                    <StyledBadge color="error" badgeContent={dealers.length}>
                                        AMC Instance : {amcInstance.name}
                                    </StyledBadge>
                                </Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    elevation={1}
                                >
                                    <Box style={{ height: '100%', width: '500px' }}>
                                        <DataGrid
                                            autoHeight
                                            columns={[
                                                {
                                                    width: 180,
                                                    field: 'dspAdvertiser',
                                                    headerName: 'DSP Advertiser ID',
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: 'grid-header',
                                                },
                                                {
                                                    width: 160,
                                                    field: 'dealerName',
                                                    headerName: 'Advertiser Name',
                                                    sortable: false,
                                                    filterable: false,
                                                    headerClassName: 'grid-header',
                                                    flex: 1,
                                                },
                                            ]}
                                            rows={dealers}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                        />
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </Block>

                    <TabsContainer>
                        <Tabs value={selectedTab} onChange={selectTab}>
                            <Tab label="Sql" style={{ textTransform: 'none' }} />
                            <Tab label="Executions" style={{ textTransform: 'none' }} />
                        </Tabs>
                    </TabsContainer>

                    <Block py={0}>
                        <TabPanel value={selectedTab} index={0}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <AceEditor
                                            mode="sql"
                                            theme="sqlserver"
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.08)',
                                            }}
                                            width="100%"
                                            height="300px"
                                            value={workflow.sqlQuery}
                                            onChange={handleEditSQL}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                startIcon={<SaveOutlined />}
                                                onClick={handleSave}
                                                variant="contained"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                startIcon={<DirectionsRunOutlined />}
                                                onClick={handleOpenRun}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Run
                                            </Button>
                                            <div style={{ flex: 1 }} />
                                            <Button
                                                startIcon={<CloseOutlined />}
                                                onClick={handleDelete}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Delete
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>

                        <TabPanel value={selectedTab} index={1}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <AmcWorkflowExecutionsGrid
                                    amcInstanceId={amcInstance.id}
                                    workflowId={workflow.workflowId}
                                />
                            </div>
                        </TabPanel>
                    </Block>
                </Column>
            </Block>

            <InfoMessage {...infoMessage}></InfoMessage>

            <AmcWorkflowExecuteDialog open={runDialogOpen} onClose={runWorkflow} />
        </Column>
    );
}
