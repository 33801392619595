import Block from '../../../components/Block';
import Column from '../../../components/Column';
import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { UserContext } from '../../../App';
import DataSetGridView from './DataSetGridView';
import AccessGuard from '../../../components/AccessGuard';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../../types/User';
import DataSetCardView from './DataSetCardView';
import { GridViewOutlined, ViewListOutlined } from '@mui/icons-material';
import Row from '../../../components/Row';

import { useAdvertiserDataSetSummaryListFilter } from '../../../hooks/Filters/AdvertiserDataSetSummaryListFilter';
import AmcPageHeader from '../partials/AmcPageHeader';

export default function DataSetPage() {
    const [dataSetSummaryList, setDataSetSummaryList] = useState<AdvertiserDataSetSummary[]>([]);

    const {
        filteredAdvertiserDataSetSummaries,
        advertiserDataSetSummaryListCriteria,
        setAdvertiserDataSetSummaryListCriteria,
    } = useAdvertiserDataSetSummaryListFilter(dataSetSummaryList);

    const { userContext } = useContext(UserContext);

    const [view, setView] = useState<'grid' | 'list' | string>('list');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleView = (event: MouseEvent<HTMLElement>, newView: string): void => {
        setView(newView ? newView : 'list');
    };

    const getDataSetSummary = useCallback(() => {
        setIsLoading(true);
        ApiService.getDataSetSummary(
            advertiserDataSetSummaryListCriteria.agencyId,
            advertiserDataSetSummaryListCriteria.advertiserId || null
        )
            .then((response) => {
                setDataSetSummaryList(response.data);
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [advertiserDataSetSummaryListCriteria]);

    useEffect(() => {
        getDataSetSummary();
    }, [advertiserDataSetSummaryListCriteria.advertiserId, getDataSetSummary]);

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader
                criteria={advertiserDataSetSummaryListCriteria}
                setCriteria={setAdvertiserDataSetSummaryListCriteria}
            />

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                        <>
                            <Row alignItems="center" justifyContent="space-between">
                                <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                                    Note:{' '}
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        variant="subtitle2"
                                        fontWeight={500}
                                    >
                                        Data sets are created using Advertiser-Submitted First-Party Data.
                                    </Typography>
                                </Typography>

                                <ToggleButtonGroup
                                    size="small"
                                    value={view}
                                    onChange={handleView}
                                    aria-label="view"
                                    exclusive={true}
                                >
                                    <Tooltip title="List View">
                                        <ToggleButton value="list" aria-label="list view">
                                            <ViewListOutlined />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Grid View">
                                        <ToggleButton value="grid" aria-label="grid view">
                                            <GridViewOutlined />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Row>

                            {view === 'list' && (
                                <>
                                    <DataSetGridView
                                        isLoading={isLoading}
                                        dataSetSummaryList={filteredAdvertiserDataSetSummaries}
                                        agencyId={advertiserDataSetSummaryListCriteria.agencyId}
                                        userContext={userContext}
                                        onCreateAudience={() => {
                                            getDataSetSummary();
                                        }}
                                    />
                                </>
                            )}

                            {view === 'grid' && (
                                <DataSetCardView
                                    dataSetSummaryList={filteredAdvertiserDataSetSummaries}
                                    agencyId={advertiserDataSetSummaryListCriteria.agencyId}
                                    userContext={userContext}
                                />
                            )}
                        </>
                    </AccessGuard>
                    <AccessGuard accessGroup={USER_GROUP_AGENCY}>
                        <>
                            <DataSetCardView
                                dataSetSummaryList={filteredAdvertiserDataSetSummaries}
                                agencyId={advertiserDataSetSummaryListCriteria.agencyId}
                                userContext={userContext}
                            />
                        </>
                    </AccessGuard>
                </Column>
            </Block>
        </Column>
    );
}
