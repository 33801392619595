import { Workflow as Resource } from '../../types/Workflow';
import { useResourceListFilter } from './ResourceListFilter';

interface ResourceListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseResourceListFilterState {
    filteredAmcGlobalWorkflows: Resource[];
    amcGlobalWorkflowListCriteria: ResourceListCriteria;
    setAmcGlobalWorkflowListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetAmcGlobalWorkflowListCriteria: () => void;
    amcGlobalWorkflows: Resource[];
    setAmcGlobalWorkflows: (resources: Resource[]) => void;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

const filterResources = (resources: Resource[], resourceListCriteria: ResourceListCriteria): Resource[] => {
    return resources;
};

const useAmcGlobalWorkflowListFilter = (
    initialResources: Resource[] = [],
    initialResourceListCriteria: ResourceListCriteria = initialResourceListCriteriaState
): UseResourceListFilterState => {
    const { resourceListCriteria, setResourceListCriteria, filteredResources, resources, setResources } =
        useResourceListFilter<Resource>({
            initialResources,
            initialResourceListCriteria: initialResourceListCriteriaState,
            filterResources,
        });

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    return {
        filteredAmcGlobalWorkflows: filteredResources,
        amcGlobalWorkflowListCriteria: resourceListCriteria,
        setAmcGlobalWorkflowListCriteria: setResourceListCriteria,
        resetAmcGlobalWorkflowListCriteria: resetResourceListCriteria,
        amcGlobalWorkflows: resources,
        setAmcGlobalWorkflows: setResources,
    };
};

export type {
    UseResourceListFilterState as UseAmcGlobalWorkflowListFilterState,
    ResourceListCriteria as AmcGlobalWorkflowListCriteria,
};
export { useAmcGlobalWorkflowListFilter };
