import { useContext, useEffect } from 'react';
import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { SectionHeading } from '../../components/SectionHeading';
import { UserContext } from '../../App';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAdvertiserAttribution from '../../hooks/useAdvertiserAttribution';
import usePolkAttribution from '../../hooks/usePolkAttribution';
import AttributionExportMenuButton from './AttributionExportMenuButton';
import PolkMatchbackMetrics from './PolkMatchbackMetrics';
import PolkMatchbackDataTabs from './PolkMatchbackDataTabs';

export default function PolkMatchbackTab() {
    const { userContext } = useContext(UserContext);
    const {
        dealer,
        attributionDate,
        attributionStatusUpdateDateFormatted,
        // attributionStatus,
        fetchAttributionStatus,
    } = useAttributionPageContext();

    const { fetchPerformances, fetchPreviousPerformances } = useAdvertiserAttribution();
    const { fetchPolkSalesWorkflows } = usePolkAttribution();

    useEffect(() => {
        fetchAttributionStatus();
    }, [fetchAttributionStatus]);

    useEffect(() => {
        fetchPolkSalesWorkflows();
    }, [fetchPolkSalesWorkflows]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchPreviousPerformances();
    }, [fetchPreviousPerformances]);

    return (
        <Column gap={3} sx={{ mt: (theme) => theme.spacing(1) }}>
            <Row>
                <Row gap={0}>
                    {attributionStatusUpdateDateFormatted && (
                        <Row gap={1} alignItems="center" justifyContent="flex-start">
                            <Row gap={0.5} sx={{ width: 'auto' }}>
                                <UpdateOutlinedIcon color="success" fontSize="small" />
                                <Typography variant="body2" color="text.secondary">
                                    Last updated{' '}
                                    <Typography component="span" variant="body2" color="text.primary" fontWeight={500}>
                                        {attributionStatusUpdateDateFormatted}
                                    </Typography>
                                </Typography>
                            </Row>

                            <Divider orientation="vertical" sx={{ height: 14 }} />

                            <Row gap={0.5} alignItems="center" sx={{ width: 'auto' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Data Source
                                </Typography>

                                <Tooltip title="Sales data provided by S&P Global Mobility aka Polk Automotive Solutions">
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            </Row>
                        </Row>
                    )}
                </Row>

                <Printable show={false}>
                    <Row alignItems="center" justifyContent="flex-end">
                        {userContext.isAdvertiser() ? (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    component={Link}
                                    to={`mailto:support@cognitionads.com?subject=Request to Map Polk Data for Advertiser`}
                                >
                                    Update Advertisers & Competitors
                                </Button>
                            </>
                        ) : (
                            <>
                                {dealer && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        component={Link}
                                        to={`/advertisers/${dealer.id}/#polkSales`}
                                    >
                                        Update Advertisers & Competitors
                                    </Button>
                                )}
                            </>
                        )}

                        <AttributionExportMenuButton />
                    </Row>
                </Printable>
            </Row>

            <Printable show={true}>
                <>
                    {dealer && (
                        <Row justifyContent="center" alignItems="center">
                            {dealer.logoUrl && dealer.logoUrl.length > 0 ? (
                                <img
                                    src={dealer.logoUrl}
                                    alt={`${dealer.dealerName}'s Logo`}
                                    style={{ maxHeight: 128 }}
                                />
                            ) : (
                                <Typography variant="h4">{dealer.dealerName}</Typography>
                            )}
                        </Row>
                    )}
                    <Row justifyContent="center" alignItems="center">
                        <Typography variant="h5">
                            {moment(Utils.getDate(attributionDate)).format('MMMM YYYY')}
                        </Typography>
                    </Row>
                </>
            </Printable>

            <div>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <SectionHeading divider={false}>Overview</SectionHeading>
                        </Stack>
                    </Column>
                </Stack>
            </div>

            <div>
                <PolkMatchbackMetrics />
            </div>

            <Box>
                <PolkMatchbackDataTabs />
            </Box>

            <Box
                sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                    py: 2,
                }}
            >
                <Stack direction="row" spacing={1.5} justifyContent="center" alignItems="center">
                    <Typography variant="body2" color="text.primary">
                        Powered By
                    </Typography>

                    <img
                        src="/S&P_Global_Mobility.png"
                        alt="Powered By S&P Global Mobility"
                        style={{
                            height: 30,
                        }}
                    />
                </Stack>
            </Box>
        </Column>
    );
}
