import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button } from '@mui/material';
import { AMCWorkflow } from '../../../types/AMCData';
import ApiService from '../../../ApiService';
import { GridRowParams } from '@mui/x-data-grid';
import { AmcInstance } from '../../../types/AmcInstance';
import { useNavigate } from 'react-router-dom';
import CDGrid from '../../../components/CDGrid';
import { CDTextField } from '../../../components/CDTextField';
import { useAmcAdvertiserWorkflowListFilter } from '../../../hooks/Filters/AmcAdvertiserWorkflowListFilter';
import AmcPageHeader from '../partials/AmcPageHeader';

export default function AmcWorkflowsPage() {
    const navigate = useNavigate();
    let id = '';
    if (localStorage.getItem('amcInstanceId')) {
        id = localStorage.getItem('amcInstanceId') as string;
    }
    const [amcInstanceId, setAmcInstanceId] = useState<string>(id);
    const [amcInstance, setAmcInstance] = useState<AmcInstance | undefined>();
    const [amcInstances, setAmcInstances] = useState<AmcInstance[]>([]);
    const [workflows, setWorkflows] = useState<AMCWorkflow[] | null>([]);

    const { filteredAmcAdvertiserWorkflows, amcAdvertiserWorkflowListCriteria, setAmcAdvertiserWorkflowListCriteria } =
        useAmcAdvertiserWorkflowListFilter(workflows || []);

    const fetchAmcWorkflowList = useCallback(() => {
        if (amcInstanceId !== '') {
            getWorkflows(amcInstanceId);
        }
    }, [amcInstanceId]);

    const fetchAmcInstanceList = useCallback(() => {
        ApiService.getAmcInstanceList({
            agencyId: amcAdvertiserWorkflowListCriteria.agencyId || null,
            advertiserId: amcAdvertiserWorkflowListCriteria.advertiserId || null,
            searchText: amcAdvertiserWorkflowListCriteria.searchText || '',
        })
            .then((response) => {
                setAmcInstances(response.data);
                setAmcInstance(response.data.find((i) => i.id + '' === id));
            })
            .catch(() => {});
    }, [amcAdvertiserWorkflowListCriteria, id]);

    useEffect(() => {
        fetchAmcInstanceList();
    }, [fetchAmcInstanceList]);

    useEffect(() => {
        fetchAmcWorkflowList();
    }, [fetchAmcWorkflowList]);

    function onSelectInstance(_event: any, newValue: AmcInstance | null) {
        if (newValue) {
            let amcInstanceId = newValue.id + '';
            let amcInstance = amcInstances.find((i) => i.id + '' === amcInstanceId);
            setAmcInstance(amcInstance);
            localStorage.setItem('amcInstanceId', amcInstanceId);
            setAmcInstanceId(amcInstanceId);
            getWorkflows(amcInstanceId);
        }
    }

    function getWorkflows(amcInstanceId: string) {
        setWorkflows(null);
        ApiService.getAmcWorkflows(+amcInstanceId)
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function handleAdd() {
        navigate('new', {
            state: {
                id: null,
                sqlQuery: '',
                isNew: true,
                amcInstance: amcInstance,
            },
        });
    }

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate(params.row.workflowId, {
                state: {
                    ...params.row,
                    isNew: false,
                    amcInstance: amcInstance,
                },
            });
        }
    }

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader
                criteria={amcAdvertiserWorkflowListCriteria}
                setCriteria={setAmcAdvertiserWorkflowListCriteria}
            />

            <Block sx={{ height: '100%' }}>
                <Column gap={2}>
                    <Row justifyContent="flex-end">
                        <Box sx={{ minWidth: 300 }}>
                            <Autocomplete
                                options={amcInstances}
                                value={amcInstance}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option?.id + '' === value?.id + ''}
                                size="small"
                                fullWidth={false}
                                onChange={onSelectInstance}
                                renderInput={(params) => (
                                    <CDTextField
                                        style={{ width: '100%' }}
                                        color="primary"
                                        shrinkLabel={false}
                                        required={true}
                                        variant="outlined"
                                        {...params}
                                        label="Instance"
                                        value={amcInstanceId}
                                    />
                                )}
                            />
                        </Box>

                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleAdd}
                            disabled={amcInstanceId === ''}
                        >
                            Add Advertiser Workflow
                        </Button>
                    </Row>

                    <CDGrid
                        getRowId={(row) => row.workflowId}
                        columns={[
                            {
                                width: 400,
                                field: 'workflowId',
                                headerName: 'Workflow ID',
                                renderCell: (params) => (
                                    <Button
                                        onClick={() => {
                                            handleEditWorkflow(params.value);
                                        }}
                                    >
                                        {params.value}
                                    </Button>
                                ),
                            },
                            {
                                width: 200,
                                field: 'sqlQuery',
                                headerName: 'SQL',
                                flex: 1,
                            },
                        ]}
                        rows={filteredAmcAdvertiserWorkflows === null ? [] : filteredAmcAdvertiserWorkflows}
                        onRowClick={handleEditWorkflow}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Column>
            </Block>
        </Column>
    );
}
