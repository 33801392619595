import { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import ApiService from '../../ApiService';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import { Dealer } from '../../types/Dealer';
import { PrintContext } from '../../hooks/usePrint';
import { ReportingDashboardPageContext } from '../../hooks/useReportingDashboardPage';
import usePrint from '../../hooks/usePrint';
import useReportingDashboardPage from '../../hooks/useReportingDashboardPage';
import ReportingCriteriaForm from './partials/Reporting/ReportingCriteriaForm';
import ReportingMediaTabs, { TABS as MEDIA_TABS } from './partials/Reporting/Tabs/ReportingMediaTabs';
import ReportingPerformanceTabPanels from './partials/Reporting/Tabs/ReportingPerformanceTabPanels';

import ReportingAdvertiserCountLabel from './partials/Reporting/ReportingAdvertiserCountLabel';
import ReportingCampaignStrategyTypeSelect from './partials/Reporting/ReportingCampaignStrategyTypeSelect';
import ReportingPerformanceExportMenuButton from './partials/Reporting/ReportingPerformanceExportMenuButton';
import ReportingPerformanceMetrics from './partials/Reporting/ReportingPerformanceMetrics';

export default function ReportingDashboardPage() {
    const theme = useTheme();
    const print = usePrint();
    const reportingDashboardPage = useReportingDashboardPage();
    const {
        isFetchingPerformances,
        fetchPerformances,
        fetchActiveMediaTypes,
        performances,
        reportingCriteria,
        setReportingCriteria,
        mediaTabs,
        activeStrategyTypes,
    } = reportingDashboardPage;

    // const [reportingCriteria, setReportingCriteria] = useState<ReportingCriteria>({
    //     agencyIds: [],
    //     dealerIds: [],
    //     startDate: new Date(),
    //     endDate: new Date(),
    //     mediaType: '',
    //     strategyType: '',
    // });
    const [isFetchingDealers, setIsFetchingDealers] = useState<boolean>(false);
    const [activeAdvertiserCount, setActiveAdvertiserCount] = useState<number>(0);
    const [totalAdvertiserCount, setTotalAdvertiserCount] = useState<number>(0);
    const [totalActiveAdvertiserCount, setTotalActiveAdvertiserCount] = useState<number>(0);

    const handleChangeMediaTab = useCallback(
        (event: SyntheticEvent, newActiveTab: number) => {
            mediaTabs.handleChangeTab(event, newActiveTab);

            let mediaType: string = '';

            switch (newActiveTab) {
                case MEDIA_TABS.ALL:
                    mediaType = '';
                    break;
                case MEDIA_TABS.DISPLAY:
                    mediaType = 'Display';
                    break;
                case MEDIA_TABS.VIDEO:
                    mediaType = 'Video';
                    break;
                case MEDIA_TABS.AUDIO:
                    mediaType = 'Audio';
                    break;
                case MEDIA_TABS.ONLINE_VIDEO:
                    mediaType = 'Online Video';
                    break;
                case MEDIA_TABS.STREAMING_TV:
                    mediaType = 'Streaming TV';
                    break;
            }

            setReportingCriteria({
                ...reportingCriteria,
                mediaType: mediaType,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mediaTabs]
    );

    const isFetchingAdvertiserCount = useMemo(() => {
        return isFetchingPerformances || isFetchingDealers;
    }, [isFetchingPerformances, isFetchingDealers]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchActiveMediaTypes();
    }, [fetchActiveMediaTypes]);

    const setupAdvertiserCounts = useCallback(() => {
        const distinctDealerIds = [...new Set(performances.map((performance: any) => performance.dealerId))];
        setActiveAdvertiserCount(distinctDealerIds.length);

        setTotalAdvertiserCount(totalActiveAdvertiserCount);

        if (reportingCriteria.dealerIds.length > 0) {
            setTotalAdvertiserCount(reportingCriteria.dealerIds.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria.dealerIds, performances, totalActiveAdvertiserCount]);

    useEffect(() => {
        setupAdvertiserCounts();
    }, [setupAdvertiserCounts]);

    return (
        <PrintContext.Provider value={print}>
            <ReportingDashboardPageContext.Provider value={reportingDashboardPage}>
                <Column gap={0} flex={1}>
                    <Block>
                        <Column gap={1}>
                            <Row spacing={2} justifyContent="space-between" alignItems="center">
                                <Heading>Reporting</Heading>

                                <Row justifyContent="flex-end"></Row>
                            </Row>
                        </Column>
                    </Block>

                    <Block py={0}>
                        <Row spacing={2} justifyContent="space-between" alignItems="center">
                            <Row spacing={2} alignItems="center">
                                <Box
                                    sx={{
                                        '&': {
                                            width: '100%',

                                            [theme.breakpoints.up('xl')]: {
                                                width: 960,
                                            },
                                        },
                                    }}
                                >
                                    <ReportingCriteriaForm
                                        reportingCriteria={reportingCriteria}
                                        onChangeReportingCriteria={setReportingCriteria}
                                        onFetchingDealers={() => {
                                            setIsFetchingDealers(true);
                                        }}
                                        onFetchDealers={(dealers: Dealer[]): void => {
                                            setIsFetchingDealers(false);

                                            setTotalActiveAdvertiserCount(
                                                dealers.filter(
                                                    (dealer: { activeStatus: boolean }) => dealer.activeStatus
                                                ).length
                                            );
                                        }}
                                    />
                                </Box>

                                <Box>
                                    {Object.keys(activeStrategyTypes).length > 0 && (
                                        <ReportingCampaignStrategyTypeSelect
                                            strategyTypes={activeStrategyTypes}
                                            reportingCriteria={reportingCriteria}
                                            onChangeReportingCriteria={setReportingCriteria}
                                        />
                                    )}
                                </Box>
                            </Row>
                        </Row>
                    </Block>

                    <Block disableGutters>
                        <ReportingMediaTabs value={mediaTabs.activeTab} onChange={handleChangeMediaTab} />
                    </Block>

                    <Block>
                        <Column>
                            <Row justifyContent="space-between">
                                <ReportingAdvertiserCountLabel
                                    loading={isFetchingAdvertiserCount}
                                    activeAdvertiserCount={activeAdvertiserCount}
                                    totalAdvertiserCount={totalAdvertiserCount}
                                />

                                <ReportingPerformanceExportMenuButton disabled={false} />
                            </Row>
                        </Column>
                    </Block>

                    <Block>
                        <Column>
                            <Box>
                                <ReportingPerformanceMetrics />
                            </Box>
                        </Column>

                        <Column>
                            <ReportingPerformanceTabPanels />
                        </Column>
                    </Block>
                </Column>
            </ReportingDashboardPageContext.Provider>
        </PrintContext.Provider>
    );
}
