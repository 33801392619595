import { AMCWorkflow as Resource } from '../../types/AMCData';
import { useResourceListFilter } from './ResourceListFilter';

interface ResourceListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseResourceListFilterState {
    filteredAmcAdvertiserWorkflows: Resource[];
    amcAdvertiserWorkflowListCriteria: ResourceListCriteria;
    setAmcAdvertiserWorkflowListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetAmcAdvertiserWorkflowListCriteria: () => void;
    amcAdvertiserWorkflows: Resource[];
    setAmcAdvertiserWorkflows: (resources: Resource[]) => void;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

const filterResources = (resources: Resource[], resourceListCriteria: ResourceListCriteria): Resource[] => {
    return resources;
};

const useAmcAdvertiserWorkflowListFilter = (
    initialResources: Resource[] = [],
    initialResourceListCriteria: ResourceListCriteria = initialResourceListCriteriaState
): UseResourceListFilterState => {
    const { resourceListCriteria, setResourceListCriteria, filteredResources, resources, setResources } =
        useResourceListFilter<Resource>({
            initialResources,
            initialResourceListCriteria: initialResourceListCriteriaState,
            filterResources,
        });

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    return {
        filteredAmcAdvertiserWorkflows: filteredResources,
        amcAdvertiserWorkflowListCriteria: resourceListCriteria,
        setAmcAdvertiserWorkflowListCriteria: setResourceListCriteria,
        resetAmcAdvertiserWorkflowListCriteria: resetResourceListCriteria,
        amcAdvertiserWorkflows: resources,
        setAmcAdvertiserWorkflows: setResources,
    };
};

export type {
    UseResourceListFilterState as UseAmcAdvertiserWorkflowListFilterState,
    ResourceListCriteria as AmcAdvertiserWorkflowListCriteria,
};
export { useAmcAdvertiserWorkflowListFilter };
