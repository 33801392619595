import { Dayjs } from 'dayjs';
import moment from 'moment';

import { CampaignCriteria } from '../../types/Campaign';
import CDMonthDatePicker from '../../components/CDMonthDatePicker';

export interface CampaignMonth {
    id: string;
    label: string;
    startDate: Date;
    endDate: Date;
}

export default function CampaignMonthSelect(props: {
    campaignCriteria: CampaignCriteria;
    changeSearchCriteria: Function;
}) {
    const { campaignCriteria, changeSearchCriteria } = props;

    const handleChange = (newDate: Dayjs | null): void => {
        if (newDate) {
            changeSearchCriteria({
                ...campaignCriteria,
                startDate: newDate.toDate(),
                endDate: newDate.endOf('month').toDate(),
            });
        }
    };

    const generateDateRange = () => {
        const dateRange: { startDate: Date; endDate: Date } = {
            startDate: moment().startOf('month').subtract(12, 'months').toDate(),
            endDate: moment().startOf('month').add(1, 'months').toDate(),
        };

        return dateRange;
    };

    const { startDate: minDate, endDate: maxDate } = generateDateRange();

    return (
        <>
            <CDMonthDatePicker
                label="Month"
                value={campaignCriteria.startDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleChange}
                sx={{ width: 200 }}
            />
        </>
    );
}
