import { Button, Chip } from '@mui/material';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';
import { useEffect, useState } from 'react';
import { AmcAttribution } from '../../types/AmcAttribution';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../ApiService';
import CDGrid from '../../components/CDGrid';
import AmcAttributionCreateFormDialog from './AmcAttributionCreateFormDialog';

import { useAmcAttributionListFilter } from '../../hooks/Filters/AmcAttributionListFilter';
import AmcPageHeader from '../amc/partials/AmcPageHeader';

export default function AmcAttributionList() {
    const [amcAttributions, setAmcAttributions] = useState<AmcAttribution[]>([]);
    const navigate = useNavigate();
    const [amcAttributionFormDialogOpen, setAmcAttributionFormDialogOpen] = useState<boolean>(false);

    const { amcAttributionListCriteria, setAmcAttributionListCriteria } = useAmcAttributionListFilter(amcAttributions);

    const refresh = () => {
        ApiService.getAmcAttributions(
            amcAttributionListCriteria.agencyId || null,
            amcAttributionListCriteria.advertiserId || null
        )
            .then(function (response) {
                setAmcAttributions(response.data);
            })
            .catch(() => {
                setAmcAttributions([]);
            });
    };

    useEffect(() => {
        ApiService.getAmcAttributions(
            amcAttributionListCriteria.agencyId || null,
            amcAttributionListCriteria.advertiserId || null
        )
            .then(function (response) {
                setAmcAttributions(response.data);
            })
            .catch(() => {
                setAmcAttributions([]);
            });
    }, [amcAttributionListCriteria]);

    return (
        <>
            <AmcPageHeader criteria={amcAttributionListCriteria} setCriteria={setAmcAttributionListCriteria} />

            <Block sx={{ height: '100%' }}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="flex-end">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                setAmcAttributionFormDialogOpen(true);
                            }}
                        >
                            Add Offline Attribution
                        </Button>
                    </Row>

                    <CDGrid
                        columns={[
                            {
                                width: 110,
                                field: 'date',
                                headerName: 'Date',
                                headerClassName: 'grid-header',
                                valueGetter: (params) => {
                                    return new Date(params.value).toLocaleDateString('en-US', {
                                        month: 'short',
                                        year: 'numeric',
                                    });
                                },
                            },
                            {
                                width: 120,
                                field: 'status',
                                headerName: 'Status',
                                headerClassName: 'grid-header',
                                renderCell: (params) => (
                                    <Chip
                                        label={params.value}
                                        size="small"
                                        variant={params.value === 'Published' ? 'filled' : 'outlined'}
                                        color={params.value === 'Published' ? 'success' : 'warning'}
                                    />
                                ),
                            },
                            {
                                width: 160,
                                field: 'dealer',
                                headerName: 'Advertiser',
                                headerClassName: 'grid-header',
                                valueGetter: (params) => {
                                    return params.value.dealerName;
                                },
                            },
                            {
                                width: 150,
                                field: 'campaignName',
                                headerName: 'Campaign Name',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 110,
                                field: 'dataSetName',
                                headerName: 'Data Type',
                                headerClassName: 'grid-header',
                            },

                            {
                                width: 150,
                                field: 'recordsSubmitted',
                                headerName: 'Records Submitted',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 150,
                                field: 'matchedAmazonRecords',
                                headerName: 'Matched Amazon Records',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 150,
                                field: 'attributedUserRecords',
                                headerName: 'Attributed User Records',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 110,
                                field: 'adBudget',
                                type: 'number',
                                headerName: 'Ad Budget',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 110,
                                field: 'adSpend',
                                type: 'number',
                                headerName: 'Ad Spend',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 110,
                                field: 'gross',
                                type: 'number',
                                headerName: 'Gross',
                                headerClassName: 'grid-header',
                            },
                        ]}
                        autoHeight={false}
                        autoPageSize={true}
                        rows={amcAttributions}
                        onRowClick={(params) => {
                            navigate('' + params.row.id);
                        }}
                    />
                </Column>
            </Block>

            {amcAttributionFormDialogOpen && (
                <AmcAttributionCreateFormDialog
                    open={amcAttributionFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setAmcAttributionFormDialogOpen(false);
                    }}
                />
            )}
        </>
    );
}
