import { Alert, Box, Chip, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { Agency } from '../../types/Agency';
import { Dealer } from '../../types/Dealer';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import CDMonthDatePicker from '../../components/CDMonthDatePicker';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import { TabsContainer } from '../../components/Tabs';
import TabPanel from '../../components/TabPanel';
import { AdvertiserContext } from '../../AdvertiserContext';
import { UserContext } from '../../App';
import { PrintContext } from '../../hooks/usePrint';
import usePrint from '../../hooks/usePrint';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

import { TABS as DATA_TABS } from './PolkMatchbackDataTabs';
import { AttributionPageContext, TABS } from '../../hooks/useAttributionPage';
import useAttributionPage from '../../hooks/useAttributionPage';
import AdvertiserMatchbackSetup from './AdvertiserMatchbackSetup';
import AdvertiserMatchbackTab from './AdvertiserMatchbackTab';
import PolkMatchbackSetup from './PolkMatchbackSetup';
import PolkMatchbackTab from './PolkMatchbackTab';
import { USER_GROUP_ADMIN } from '../../types/User';
import AccessGuard from '../../components/AccessGuard';
import AgencySelector from '../../components/AgencySelector';
import DealerSelector from '../../components/DealerSelector';

export default function AttributionPage() {
    const [agency, setAgency] = useState<Agency | null>(null);
    // const [dealer, setDealer] = useState<Dealer | null>(null);

    const print = usePrint();
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);
    const { userContext } = useContext(UserContext);

    const dealer = advertiserContext.dealer;

    const attributionPage = useAttributionPage({
        dealer: dealer,
    });
    const {
        tab,
        setTab,
        attributionDate,
        setAttributionDate,
        setAdvertiserSalesUpdateDate,
        polkDealerDealers,
        fetchPolkDealerDealers,
    } = attributionPage;

    const [salesDataUploads, setSalesDataUploads] = useState<SalesDataUpload[]>([]);

    const isPolkMatchbackSetup = useMemo(() => {
        if (polkDealerDealers.length > 0) {
            return true;
        }
        return agency && agency?.polkSalesIsActive;
    }, [agency, polkDealerDealers]);

    const isAdvertiserMatchbackSetup = useMemo(() => {
        return salesDataUploads.length > 0;
    }, [salesDataUploads]);

    function selectTab(_event: SyntheticEvent, value: any) {
        switch (value) {
            case TABS.ADVERTISER_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.CAMPAIGN_PERFORMANCE as any as string);
                localStorage.setItem('attribution.advertiserView', 'chart');
                break;

            case TABS.POLK_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);
                localStorage.setItem('attribution.polkView', 'chart');
                break;
        }

        setTab(value);
    }

    function handleAttributionDate(_attributionDate: Date) {
        setAttributionDate(_attributionDate);
    }

    const generateDateRange = () => {
        const dateRange: { startDate: Date; endDate: Date } = {
            startDate: moment('2024-01-01').startOf('day').toDate(),
            endDate: moment().startOf('day').toDate(),
        };

        if (!Utils.isAfterFirstSundayOfCurrentMonth()) {
            dateRange.endDate = moment(dateRange.endDate).subtract(1, 'months').toDate();
        }

        return dateRange;
    };

    const { startDate: attributionMinDate, endDate: attributionMaxDate } = generateDateRange();

    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    useEffect(() => {
        if (!Utils.isAfterFirstSundayOfCurrentMonth()) {
            setAttributionDate(attributionMaxDate);
        }

        localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (advertiserContext?.dealer?.id) {
            setDealer(advertiserContext.dealer);
        } else if (userContext.isAdvertiser()) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    if (response.data.length === 1) {
                        setDealer(response.data[0]);
                    } else {
                        setDealer(null);
                    }
                })
                .catch();
        } else {
            setDealer(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertiserContext.dealer, userContext]);

    useEffect(() => {
        fetchPolkDealerDealers();
    }, [fetchPolkDealerDealers]);

    useEffect(() => {
        if (dealer?.id) {
            ApiService.getSalesDataUploadsByDealer(null, dealer.id).then((response) => {
                setSalesDataUploads(response.data);
            });
        } else {
            setSalesDataUploads([]);
        }
    }, [dealer]);

    useEffect(() => {
        if (dealer !== null && (agency === null || dealer.agencyId !== agency.id)) {
            if (dealer?.agencyId) {
                ApiService.getAgency(dealer.agencyId).then((response) => {
                    setAgency(response.data);
                });
            }
        }
    }, [agency, dealer]);

    useEffect(() => {
        if (salesDataUploads && salesDataUploads.length) {
            setAdvertiserSalesUpdateDate(Utils.getDate(salesDataUploads[0].dateUploaded));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesDataUploads]);

    return (
        <PrintContext.Provider value={print}>
            <AttributionPageContext.Provider value={attributionPage}>
                <Column gap={0} flex={1}>
                    <Block>
                        <Column gap={1}>
                            <Row spacing={2} justifyContent="space-between" alignItems="center">
                                <Heading>Attribution</Heading>

                                <Row justifyContent="flex-end"></Row>
                            </Row>
                        </Column>
                    </Block>

                    <Block py={0}>
                        <Row spacing={2} justifyContent="space-between" alignItems="center">
                            <Stack direction="row" spacing={1}>
                                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                    <div
                                        style={{
                                            minWidth: '280px',
                                        }}
                                    >
                                        <AgencySelector
                                            variant="outlined"
                                            label="Agency"
                                            agencySelected={(agencyId) => {
                                                setAdvertiserContext(null, agencyId);
                                            }}
                                            agencyId={advertiserContext.agencyId}
                                        />
                                    </div>
                                </AccessGuard>

                                <div
                                    style={{
                                        minWidth: '280px',
                                    }}
                                >
                                    <DealerSelector
                                        variant="outlined"
                                        label="Advertiser"
                                        agencyId={advertiserContext.agencyId}
                                        required={false}
                                        dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                                        dealerSelected={dealerSelected}
                                    />
                                </div>

                                {dealer && dealer.id && (
                                    <>
                                        <Tooltip
                                            placement="top"
                                            title="Current Month data will be available within 7 days of the start of the current month."
                                        >
                                            <Box>
                                                <CDMonthDatePicker
                                                    label="Month"
                                                    value={attributionDate}
                                                    minDate={attributionMinDate}
                                                    maxDate={attributionMaxDate}
                                                    onChange={(newDate) => {
                                                        if (newDate) {
                                                            handleAttributionDate(newDate.toDate());
                                                        }
                                                    }}
                                                    sx={{ width: 200 }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </>
                                )}
                            </Stack>
                        </Row>

                        {(!dealer || !dealer?.id) && (
                            <>
                                <Alert severity="info" sx={{ my: 2 }}>
                                    Select an Advertiser to view the available Attribution data.
                                </Alert>
                            </>
                        )}
                    </Block>

                    {dealer && dealer.id && (
                        <Block disableGutters>
                            <TabsContainer sx={{ mt: 2 }}>
                                <Tabs value={tab} onChange={selectTab}>
                                    <Tab
                                        value={TABS.POLK_REPORTED_SALES}
                                        label={
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <span>Polk Reported Sales</span>
                                                <Chip
                                                    variant="outlined"
                                                    label="New"
                                                    size="small"
                                                    color="success"
                                                    sx={{ backgroundColor: '#EDF7ED' }}
                                                />
                                            </Stack>
                                        }
                                    />
                                    <Tab
                                        value={TABS.ADVERTISER_REPORTED_SALES}
                                        label={
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <span>Advertiser Reported Sales</span>
                                                <Chip
                                                    variant="outlined"
                                                    label="New"
                                                    size="small"
                                                    color="success"
                                                    sx={{ backgroundColor: '#EDF7ED' }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </Tabs>
                            </TabsContainer>
                        </Block>
                    )}

                    {dealer && dealer.id && (
                        <Block>
                            <TabPanel index={TABS.POLK_REPORTED_SALES} value={tab} sx={{ p: 0 }}>
                                {isPolkMatchbackSetup === true && <PolkMatchbackTab />}
                                {isPolkMatchbackSetup === false && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <PolkMatchbackSetup dealer={dealer} />
                                    </Stack>
                                )}
                            </TabPanel>

                            <TabPanel index={TABS.ADVERTISER_REPORTED_SALES} value={tab} sx={{ p: 0 }}>
                                {isAdvertiserMatchbackSetup === true && <AdvertiserMatchbackTab />}
                                {isAdvertiserMatchbackSetup === false && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <AdvertiserMatchbackSetup dealer={dealer} />
                                    </Stack>
                                )}
                            </TabPanel>
                        </Block>
                    )}
                </Column>
            </AttributionPageContext.Provider>
        </PrintContext.Provider>
    );
}
