import { Divider, Toolbar } from '@mui/material';
import { useContext } from 'react';

import { AgencyContext } from '../../App';
import { USER_GROUP_ADMIN } from '../../types/User';
import AccessGuard from '../AccessGuard';
import Block from '../Block';
import Column from '../Column';
import Row from '../Row';

import AppToolbarMenu from './AppToolbarMenu';
import AppToolbarNotificationsButton from './AppToolbarNotificationsButton';

const AppToolbar = () => {
    const agency = useContext(AgencyContext);

    return (
        <Toolbar disableGutters className="App-toolbar" sx={{ height: 64 }}>
            <Column gap={0} height="100%">
                <Block py={0} flex={1}>
                    <Row justifyContent="space-between" alignItems="center" gap={3} height="100%" width="100%">
                        {agency && agency?.logoUrl && (
                            <Row gap={0}>
                                <img style={{ maxHeight: 40 }} alt="ageny logo" src={agency.logoUrl} />

                                <Divider />
                            </Row>
                        )}

                        <div style={{ flexGrow: 1 }} />

                        <Row gap={0} flex={1} justifyContent="flex-end">
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <AppToolbarNotificationsButton />
                            </AccessGuard>

                            <AppToolbarMenu />
                        </Row>

                        {/*<AccessGuard accessGroup={USER_GROUP_ADMIN}>*/}
                        {/*    <BuildInfoComponent />*/}
                        {/*</AccessGuard>*/}
                    </Row>
                </Block>

                <Divider />
            </Column>
        </Toolbar>
    );
};

export default AppToolbar;
