import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { GridToolbarContainer } from '../../../components/DataGrid';
import ApiService from '../../../ApiService';
import { Workflow } from '../../../types/Workflow';
import { useNavigate } from 'react-router-dom';
import WorkflowCreateFormDialog from './WorkflowCreateFormDialog';
import CDGrid from '../../../components/CDGrid';

import { useAmcGlobalWorkflowListFilter } from '../../../hooks/Filters/AmcGlobalWorkflowListFilter';
import AmcPageHeader from '../partials/AmcPageHeader';

export default function WorkflowsPage() {
    const navigate = useNavigate();
    const [workflows, setWorkflows] = useState<Workflow[] | null>([]);
    const [workflowFormDialogOpen, setWorkflowFormDialogOpen] = useState<boolean>(false);

    const { filteredAmcGlobalWorkflows, amcGlobalWorkflowListCriteria, setAmcGlobalWorkflowListCriteria } =
        useAmcGlobalWorkflowListFilter(workflows || []);

    useEffect(() => {
        getWorkflows();
    }, []);

    function getWorkflows() {
        ApiService.getWorkflows()
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function refresh() {
        getWorkflows();
    }

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate('' + params.row.id, {
                state: { ...params.row, isNew: false },
            });
        }
    }

    function CustomToolbar() {
        return <GridToolbarContainer></GridToolbarContainer>;
    }

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader criteria={amcGlobalWorkflowListCriteria} setCriteria={setAmcGlobalWorkflowListCriteria} />

            <Block>
                <Column gap={2}>
                    <Row justifyContent="flex-end">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                setWorkflowFormDialogOpen(true);
                            }}
                        >
                            Add Global Workflow
                        </Button>
                    </Row>

                    <Alert severity="info">Global Workflows applied to all Instances before Workflow Execution.</Alert>

                    <CDGrid
                        components={{ Toolbar: CustomToolbar }}
                        getRowId={(row) => row.workflowId}
                        columns={[
                            {
                                width: 400,
                                field: 'workflowId',
                                headerName: 'Workflow ID',
                                headerClassName: 'grid-header',
                                renderCell: (params) => (
                                    <Button
                                        onClick={() => {
                                            handleEditWorkflow(params.value);
                                        }}
                                    >
                                        {params.value}
                                    </Button>
                                ),
                            },
                            {
                                width: 200,
                                field: 'sqlQuery',
                                headerName: 'SQL',
                                headerClassName: 'grid-header',
                                flex: 1,
                            },
                        ]}
                        rows={filteredAmcGlobalWorkflows === null ? [] : filteredAmcGlobalWorkflows}
                        onRowClick={handleEditWorkflow}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Column>
            </Block>

            {workflowFormDialogOpen && (
                <WorkflowCreateFormDialog
                    open={workflowFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setWorkflowFormDialogOpen(false);
                    }}
                />
            )}
        </Column>
    );
}
