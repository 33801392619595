import { SyntheticEvent } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import { TabsContainer } from '../../../components/Tabs';
import { TabsProps } from '../../../hooks/Tabs';

const TABS = {
    INSTANCES: 0,
    DATA_SETS: 1,
    GLOBAL_WORKFLOWS: 2,
    ADVERTISER_WORKFLOWS: 3,
    OFFLINE_ATTRIBUTIONS: 4,
};

type AmcPageTabsProps = TabsProps;

const AmcPageTabs = (props: AmcPageTabsProps) => {
    const { activeTab, setActiveTab } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const handleChangeTab = useCallback(
        (event: SyntheticEvent, newActiveTab: number): void => {
            props.handleChangeTab(event, newActiveTab);

            switch (newActiveTab) {
                case TABS.INSTANCES:
                    navigate('/amcInstances');
                    break;
                case TABS.DATA_SETS:
                    navigate('/amcDataSets');
                    break;
                case TABS.GLOBAL_WORKFLOWS:
                    navigate('/workflows');
                    break;
                case TABS.ADVERTISER_WORKFLOWS:
                    navigate('/amcworkflows');
                    break;
                case TABS.OFFLINE_ATTRIBUTIONS:
                    navigate('/amcAttributionList');
                    break;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.handleChangeTab]
    );

    useEffect(() => {
        switch (location.pathname) {
            case '/amcInstances':
                setActiveTab(TABS.INSTANCES);
                break;
            case '/amcDataSets':
                setActiveTab(TABS.DATA_SETS);
                break;
            case '/workflows':
                setActiveTab(TABS.GLOBAL_WORKFLOWS);
                break;
            case '/amcworkflows':
                setActiveTab(TABS.ADVERTISER_WORKFLOWS);
                break;
            case '/amcAttributionList':
                setActiveTab(TABS.OFFLINE_ATTRIBUTIONS);
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <TabsContainer>
            <Tabs value={activeTab} onChange={handleChangeTab}>
                <Tab value={TABS.INSTANCES} label="Instances" />
                <Tab value={TABS.DATA_SETS} label="Data Sets" />
                <Tab value={TABS.GLOBAL_WORKFLOWS} label="Global Workflows" />
                <Tab value={TABS.ADVERTISER_WORKFLOWS} label="Advertiser Workflows" />
                <Tab value={TABS.OFFLINE_ATTRIBUTIONS} label="Offline Attributions" />
            </Tabs>
        </TabsContainer>
    );
};

export { TABS };
export default AmcPageTabs;
